@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'nidasns';  
  src: url('./assets/fonts/nidsans-webfont.ttf') format('opentype'); 
}


@font-face {
  font-family: 'outlier';  
  src: url('./assets/fonts/Outlier.ttf') format('opentype'); 
}

@font-face {
  font-family: 'roboto_regular';  
  src: url('./assets/fonts/Roboto-Regular.ttf') format('opentype'); 
}

@font-face {
  font-family: 'roboto_regular';  
  src: url('./assets/fonts/Roboto-Regular.ttf') format('opentype'); 
}


@font-face {
  font-family: 'sfpro_display_black';  
  src: url('./assets/fonts/SF-Pro-Display-Black.otf') format('opentype'); 
}

@font-face {
  font-family: 'sfpro_display_regular';  
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf') format('opentype'); 
}

@font-face {
  font-family: 'sfpro_text_regular';  
  src: url('./assets/fonts/SF-Pro-Text-Regular.otf') format('opentype'); 
}

@font-face {
  font-family: 'sfpro_text_bold';  
  src: url('./assets/fonts/SF-Pro-Text-Bold.otf') format('opentype'); 
}
/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(240, 95%, 7%);   /*#010123*/
  --clr-primary-2: hsl(236, 93%, 12%) ; /*#010D3C*/
  --clr-primary-3: hsl(233, 81%, 29%);  /*#0A0A4A*/

  --clr-two-1:	hsl(215, 13%, 48%);
  --clr-two-2:	hsl(215, 31%, 63%);
  --clr-two-3:	hsl(213, 29%, 81%);

  --clr-three-1: hsl(184, 65%, 59%);
  --clr-three-2: hsl(180, 98%, 56%);
  --clr-three-3: hsl(184, 80%, 74%);

  --clr-five-1: 	hsl(263, 96%, 27%);
  --clr-five-2: 	hsl(263, 93%, 40%);
  --clr-five-3: 	hsl(263, 52%, 47%);

  --clr-six-1: 	hsl(61, 68%, 40%);
  --clr-six-2: 	hsl(59, 78%, 48%);
  --clr-six-3:	hsl(69, 98%, 57%);





  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --ff-primary: 'sfpro_text_regular', sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}




.background-primary-darkest{
  background-color: var(--clr-primary-1);
}

.color-two-dark{
color: var( --clr-two-1);
}
.color-two-medium{
color: var(--clr-two-2);
}

.color-two-light{
color: var( --clr-two-3);
}

.color-three-dark{
color: var(--clr-three-1);
}
.color-three-medium{
color: var(--clr-three-2);
}

.color-three-light{
color: var(--clr-three-3);
}

.color-five-dark{
color: var(--clr-five-1);
}

.color-five-medium{
color: var(--clr-five-2);
}
.color-five-light{
color: var(--clr-five-3);
}


.color-six-medium{
color: var(--clr-six-2);
}

.color-six-light{
color: var(--clr-six-3);
}




/*
  =============== 
  Global Styles
  ===============
  */





* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'sfpro_text_regular', fallback, sans-serif;
  background: var(--clr-white);
  /* color: var(--clr-primary-3); */
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img:not(.nav-logo) {
  width: 100%;
  display: block;
}


h1{
  font-family: 'outlier', fallback, sans-serif;
  font-size: 3rem;
  text-transform: capitalize;
  color: var(--clr-two-3)
}
h2 {
  font-family: 'sfpro_text_bold', fallback, sans-serif;
  font-size: 2rem;
  color: var(--clr-primary-1);
  
}

h3 {
  font-family: 'sfpro_text_bold', fallback, sans-serif;
  font-size: 1.25rem;
  color: var(--clr-primary-2);
}
h2,h3 {

  margin-bottom: 0.75rem;
}


h4 {
  font-family: 'sfpro_text_regular', fallback, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  color: var(--clr-primary-2);
}
p {
  margin-bottom: 1.25rem;
  /* color: var(--clr-primary-2); */
}


@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

@keyframes pulsate {
  0%, 100% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(0.69);
    opacity: 1;
  }
}

.pulsate {
  animation: pulsate 2s ease-in-out infinite;
}
